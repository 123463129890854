import "../css/app.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { createRoot } from "react-dom/client";
import { createInertiaApp } from "@inertiajs/inertia-react";
import { InertiaProgress } from "@inertiajs/progress";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import * as Sentry from "@sentry/react";

const appName =
    window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.tsx`,
            import.meta.glob("./Pages/**/*.tsx")
        ),
    setup({ el, App, props }) {
        Sentry.init({
            dsn: "https://2b4dd03db5b4691a0457a8f38f44f7b3@o4507260997206016.ingest.us.sentry.io/4507261002842112",
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            enabled:
                props?.initialPage?.props?.app_env === "local" ? false : true,
        });

        if (props.initialPage.props.auth?.user) {
            Sentry.setUser({
                email: props.initialPage.props.auth.user.email,
                username: props.initialPage.props.auth.user.name,
            });
        }

        const root = createRoot(el);
        root.render(<App {...props} />);
    },
});

InertiaProgress.init({ color: "#4B5563" });
